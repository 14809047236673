import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Element } from 'react-scroll';
import ContactForm from '../ContactForm';
import { RECAPTCHA_SITE_KEY } from '../../config';
import bgSrc from '../../images/img-bg.jpg';
import personSrc from '../../images/landing/mnesladek.png';
import phoneSrc from '../../images/svg/phone.svg';
import mailSrc from '../../images/svg/mail.svg';

const Section = styled.div`
  background-color: #ffffff;
  background-image: url(${bgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 3.5rem 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.60;
    z-index: 0;
  }

  @media (min-width: 768px) {
    padding: 6.25rem 0;
  }
`;

const TitleWrapper = styled.div`
  p {
    color: #ffffff;
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  z-index: 1;

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 620px;
  }

  @media (min-width: 992px) {
    width: 860px;
  }

  @media (min-width: 1200px) {
    width: 1040px;
  }

  @media (min-width: 1400px) {
    width: 1290px;
  }

  @media (min-width: 1600px) {
    width: 1520px;
  }
`;

const Title = styled.h2`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.25rem;
  z-index: 2;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
    line-height: 3rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 3.5rem;

  @media (min-width: 992px) {
    margin-top: 5.625rem;
    grid-template-columns: 1fr 1fr;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }

  @media (min-width: 992px) {
    padding-right: 2rem;
    justify-content: flex-end;
    margin-bottom: 0;
    align-items: flex-start;
  }
`;

const AvatarWrapper = styled.div`
  margin-right: 2rem;

  img {
    width: 100%;
    max-width: 120px;
    height: auto;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      max-width: 150px;
      margin-bottom: 0;
    }
  }
`;

const FormWrapper = styled.div`
  /* padding: 0 3rem 0 6.25rem; */

  @media (min-width: 1200px) {
    padding: 0 3rem 0 6.25rem;

    div:first-child {
      max-width: 500px;
    }
  }

`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #fff;
  margin-bottom: 0.25rem;
  position: relative;
  text-align: center;

  @media(min-width: 768px) {
    text-align: left;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  margin-right: 10px;
`;

const PhoneIcon = styled(phoneSrc)`
  width: 24px;
  height: auto;
`;

const MailIcon = styled(mailSrc)`
  width: 24px;
  height: auto;
`;

const Item = styled.div`
`;

const Position = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #fefefe;
  margin-bottom: 2rem;
  text-align: center;

  @media(min-width: 768px) {
    text-align: left;
  }
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  a, a:hover {
    color: #fefefe;
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
    line-height: 1.375rem;
  }
`;

const Divider = styled.span`
`;

const CustomLink = styled.span`
  cursor: pointer;
`;

const Info = styled.div`
  text-align: center;
  ${Divider} {
    display: none;
  }

  span {
    display: block;
    margin-bottom: 0.25rem;
  }

  @media (min-width: 576px) {
    ${Divider} {
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-bottom: 0;
    }
  }
`;

const Footer = styled.div`
  margin-top: 2rem;
  color: #bcbec0;
  font-size: 0.875rem;

  a, ${CustomLink} {
    text-decoration: underline;
    color: #bcbec0;
  }

  a:hover, ${CustomLink}:hover {
    color: #bcbec0;
    text-decoration: none;
  }
`;

function Contact() {
  const { t } = useTranslation();

  return (
    <Section>
      <ContentWrapper>
        <Element name="contact-element">
          <TitleWrapper>
            <Title>{t('landing.contact.title')}</Title>
            <p>{t('landing.contact.subtitle')}</p>
          </TitleWrapper>
          <Grid>
            <InfoWrapper>
              <AvatarWrapper>
                <img src={personSrc} alt={t('photo_alt')} />
              </AvatarWrapper>
              <Item>
                <Subtitle>REAL SPEKTRUM, a.s.</Subtitle>
                <Position>{t('landing.contact.contact_position')}</Position>
                <InfoText>
                  <IconWrapper>
                    <PhoneIcon />
                  </IconWrapper>
                  <p>
                    <a href="tel:+420800800099">
                      +420 800 800 099
                    </a>
                  </p>
                </InfoText>
                <InfoText>
                  <IconWrapper>
                    <MailIcon />
                  </IconWrapper>
                  <p>
                    <a href="mailto:rs@realspektrum.cz">rs@realspektrum.cz</a>
                  </p>
                </InfoText>
              </Item>
            </InfoWrapper>
            <FormWrapper>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
                <ContactForm elementId="detail-form" />
              </GoogleReCaptchaProvider>
            </FormWrapper>
          </Grid>
        </Element>
        <Footer>
          <Info>
            <span>&copy; {t('footer.info_text_1')}</span> <Divider>|</Divider> <span><a href={t('footer.privacy_policy_link')} target="_blank" rel="noreferrer" title={t('footer.privacy_policy_link_info')}>{t('footer.privacy_policy')}</a></span>
            <Divider>|</Divider> <CustomLink id="c-settings" aria-haspopup="dialog">{t('cookies.btn_settings')}</CustomLink>
          </Info>
        </Footer>
      </ContentWrapper>
    </Section>
  );
}

export default Contact;