import React from 'react';
import {graphql } from 'gatsby';
import Layout from '../../components/Landing/layout';
import Seo from '../../components/seo';
import Panel from '../../components/Landing/panel';
import { ProjectSection, StandardsSection } from '../../components/Landing/infoSection';
import Contact from '../../components/Landing/contact';
import Gallery from '../../components/Landing/gallery';

function Index() {
  return (
    <Layout showPageTitle>
      <Seo />
      <Panel />
      <ProjectSection />
      <Gallery />
      <StandardsSection />
      <Contact />
    </Layout>
  );
}

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;