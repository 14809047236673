import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { ABOUT_PROJECT } from '../../routes';

const Section = styled.div`
  background-color: #ffffff;
  padding: 3.5rem 0 1rem 0;

  .bg-img {
    margin-bottom: 3rem;
  }

  @media (min-width: 768px) {
    padding: 6.25rem 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 620px;
  }

  @media (min-width: 992px) {
    width: 860px;
  }

  @media (min-width: 1200px) {
    width: 1040px;
  }

  @media (min-width: 1400px) {
    width: 1290px;
  }

  @media (min-width: 1600px) {
    width: 1520px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const TextContent = styled.div`
  padding: 3rem 0;

  @media (min-width: 992px) {
    padding: 1rem 0rem 1rem 3rem;
  }

  @media (min-width: 1200px) {
    padding: 3rem 3rem 0 6.25rem;
  }
`;

const Title = styled.h2`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #ff940a;
  text-align: left;
  font-size: 1.75rem;
  line-height: 2.25rem;
  z-index: 2;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }
`;

const Text = styled.div`
  text-align: left;
  color: #737373;
  line-height: 1.75rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 3.5rem;
  /* z-index: 2; */

  button,
  a {
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border: 0;
    background-color: #ff940a;
    /* background-color: ${({theme}) => theme.colors.secondary}; */
    /* color: ${({theme}) => theme.colors.primary}; */
    color: #fefefe;
    padding: 0.75rem;
    /* width: 100%; */
    cursor: pointer;
    min-width: 200px;

    &:focus {
      border: 0;
      outline: 0;
      color: #fefefe;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      background-color: #303030;
      color: #fefefe;
    }

    @media (min-width: 1200px) {
      font-size: 1.25rem;
      padding: 1rem 0.5rem;
      min-width: 250px;
    }

    @media (min-width: 1400px) {
      font-size: 1.25rem;
      padding: 1rem;
      min-width: 250px;
    }
  }
`;

const LeftContent = styled(TextContent)`
  padding: 0 0 3rem;

  @media (min-width: 992px) {
    padding: 1rem 3rem 1rem 0;

    ${Title}, ${Text} {
      text-align: right;
    }

    ${ButtonWrapper} {
      justify-content: flex-end;
    }

  }

  @media (min-width: 1200px) {
    padding: 3rem 6.25rem 0 3rem;
  }
`;

export function ProjectSection() {
  const { t } = useTranslation();

  return (
    <Section>
      <ContentWrapper>
        <Grid>
          <StaticImage src="../../images/landing/img-01.jpg" placeholder="blurred" alt="Foto" layout="fullWidth" />
          <TextContent>
            <Title>{t('landing.project_section.title')}</Title>
            <Text>
              {t('landing.project_section.text')}
            </Text>
            <ButtonWrapper>
              <Link to={ABOUT_PROJECT}>{t('landing.project_section.btn_label')}</Link>
            </ButtonWrapper>
          </TextContent>
        </Grid>
      </ContentWrapper>
    </Section>
  );
}

export function StandardsSection() {
  const { t } = useTranslation();

  return (
    <Section>
      <ContentWrapper>
        <Grid>
          <LeftContent>
            <Title>
              <Trans i18nKey="landing.standards_section.title">
                message
              </Trans>
            </Title>
            <Text>
              {t('landing.standards_section.text')}
            </Text>
            <ButtonWrapper>
              <a href={t('nav.standards_link')} target="_blank" rel="noreferrer" title={t('nav.standards_link_title')}>{t('landing.standards_section.btn_label')}</a>
            </ButtonWrapper>
          </LeftContent>
          <StaticImage className="bg-img" src="../../images/landing/img-02.jpg" placeholder="blurred" alt="Foto" layout="fullWidth"  />
        </Grid>
      </ContentWrapper>
    </Section>
  );
}