import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Autoplay, Navigation  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'react-image-lightbox/style.css';
import zoomSrc from '../../images/svg/zoom.svg';

const Section = styled.div`
  background-color: #f4f4f4;
  padding: 3.5rem 0;

  @media (min-width: 768px) {
    padding: 3.75rem 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  /* width: 100%; */

  .swiper-button-prev,
  .swiper-button-next {
    color: #7b7b7b;
    /* position: relative; */
    top: auto;
    left: auto;
    right: auto;
    margin-top: 0;
  }

  .swiper-button-prev {
    margin-right: 2rem;
    left: 0.5rem;
  }

  .swiper-button-next {
    margin-left: 2rem;
    right: 0.5rem;
  }

  @media (min-width: 768px) {
    .swiper-button-prev {
      margin-right: 2rem;
      left: 2rem;
    }

    .swiper-button-next {
      margin-left: 2rem;
      right: 2rem;
    }
  }
`;

const GalleryWrapper = styled.div`
  /* max-width: 1720px; */
  width: 100%;
  padding: 0 50px;

  @media (min-width: 768px) {
    padding: 0 100px;
  }
`;

const Title = styled.h2`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #737373;
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.25rem;
  z-index: 2;
  margin-bottom: 3.125rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3.5rem;
  /* z-index: 2; */

  button,
  a {
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border: 0;
    background-color: #ffffff;
    /* background-color: ${({theme}) => theme.colors.secondary}; */
    /* color: ${({theme}) => theme.colors.primary}; */
    color: #ff940a;
    padding: 0.75rem;
    /* width: 100%; */
    cursor: pointer;
    min-width: 200px;

    &:focus {
      border: 0;
      outline: 0;
      background-color: #ffffff;
      color: #ff940a;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      background-color: #ff940a;
      color: #fefefe;
    }

    @media (min-width: 768px) {
      font-size: 1.25rem;
      padding: 1rem;
      width: 250px;
    }
  }
`;


const ZoomIcon = styled(zoomSrc)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  transform: translate(-50%, -50%);
  opacity: 0;
  fill: #ffffff;
  transition: all 300ms ease;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: all 300ms ease;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover {
    ${ZoomIcon} {
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const DATA = [1,2,3,4,5];

function Gallery() {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [images, setImages] = useState([]);
  const [type, setType] = useState('interior');

  const { t } = useTranslation();

  useEffect(() => {
    setImages(
      DATA.map(item => `/landing/gallery/${type}/0${item}.jpg`)
    );
    setSelected(0);
  },[type]);

  return (
    <Section>
      { isOpen && (
        <Lightbox
          reactModalProps={{ shouldReturnFocusAfterClose: false }}
          enableZoom={false}
          mainSrc={images[selected]}
          nextSrc={images[(selected + 1) % DATA.length]}
          prevSrc={images[(selected + DATA.length - 1) % DATA.length]}
          onCloseRequest={e => { setOpen(false);
          }}
          onMovePrevRequest={() =>
            setSelected((selected + DATA.length - 1) % DATA.length)
          }
          onMoveNextRequest={() =>
            setSelected((selected + 1) % DATA.length)
          }
        />
      )}
      <Title>{t('landing.gallery.title')}</Title>
      <Wrapper>
        <div className='swiper-button-prev'></div>
        <GalleryWrapper>
          <Swiper
              modules={[Navigation, Autoplay]}
              loop
              spaceBetween={12}
              navigation={
                {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }
              }
              slidesPerView={1}
              breakpoints={{
                360: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                }
              }}
              speed={1000}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              { type === 'interior' ?
              (
                <>
                {
                  DATA.map(item => (
                  <SwiperSlide key={item}>
                    <ImageWrapper onClick={() => { setSelected(item-1); setOpen(true);}}>
                      <Image src={`/landing/gallery/interior/0${item}-th.jpg`} alt="Foto" />
                      <ZoomIcon alt="Ikona" />
                    </ImageWrapper>
                  </SwiperSlide>
                  ))
                }
                </>
              ) : (
                <>
                    {
                  DATA.map(item => (
                  <SwiperSlide key={item}>
                    <ImageWrapper onClick={() => { setSelected(item-1); setOpen(true);}}>
                      <Image src={`/landing/gallery/exterior/0${item}-th.jpg`} alt="Foto" />
                      <ZoomIcon alt="Ikona" />
                    </ImageWrapper>
                  </SwiperSlide>
                  ))
                }
                  </>
              )}

            </Swiper>
        </GalleryWrapper>
        <div className='swiper-button-next'></div>
      </Wrapper>
      <ButtonWrapper>
        <button onClick={() => setType(prev => prev === 'interior' ? 'exterior' : 'interior')}>{t(`landing.gallery.${type === 'interior' ? 'exterior' : 'interior'}`)}</button>
      </ButtonWrapper>
    </Section>
  );
}

export default Gallery;