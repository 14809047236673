import React from 'react';
import styled from 'styled-components';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image";
import { scroller } from 'react-scroll';
import logoSrc from '../../images/landing/logo-pod-rimskym-vrchem-light.png';
import { HOME } from '../../routes';

const Wrapper = styled.div`
  position: relative;

  .bg-img {
    min-height: 450px;
  }

  @media (min-width: 768px) {
      min-height: auto;
    }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 50px;
  bottom: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* max-width: 70%; */
  text-align: center;
  padding: 0 1rem;
  z-index: 1;

  @media (min-width: 1200px) {
    top: 90px;
  }
`;

const LogoWrapper = styled.div`
  img {
    width: 150px;
    height: auto;
    margin-bottom: 2rem;

    @media (min-width: 992px) {
      width: 200px;
    }

    @media (min-width: 1400px) {
      width: 300px;
    }
  }
`;

const Title = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #fefefe;
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.25rem;
  z-index: 2;
  margin-bottom: 1.25rem;

  @media (min-width: 992px) {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    font-size: 3.5rem;
    line-height: 4.5rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1400px) {
    font-size: 5rem;
    line-height: 5.5rem;
    margin-bottom: 2rem;
  }
`;

const Subtitle = styled.p`
  color: #fefefe;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2.5rem;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }

  @media (min-width: 992px) {
    font-size: 1.375rem;
    line-height: 2rem;
    max-width: 750px;
    margin-bottom: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 5rem;
    max-width: 950px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
  z-index: 2;

  button {
    font-size: 1rem;
    text-transform: uppercase;
    border: 0;
    background-color: #ff940a;
    /* background-color: ${({theme}) => theme.colors.secondary}; */
    /* color: ${({theme}) => theme.colors.primary}; */
    color: #fefefe;
    padding: 0.75rem;
    /* width: 100%; */
    box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
    cursor: pointer;
    width: 200px;

    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      background-color: #303030;
    }

    @media (min-width: 1200px) {
      font-size: 1.25rem;
      padding: 1rem;
      width: 250px;
    }
  }
`;

function Panel() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StaticImage className="bg-img" src="../../images/landing/bg-landing.jpg" placeholder="blurred" alt="Foto" />
      <Content>
        <LogoWrapper>
          <Link to={HOME}>
            <img src={logoSrc} alt={t('logo_alt')} />
          </Link>
        </LogoWrapper>
        <div>
          <Title as="h1">{t('landing.title')}</Title>
          <Subtitle>{t('landing.subtitle')}</Subtitle>
          <ButtonWrapper>
            <button onClick={() => {
              scroller.scrollTo('contact-element', {
                duration: 500,
                delay: 0,
                smooth: 'linear',
                offset: -150,
              })
            }}>{t('landing.more_info')}</button>
          </ButtonWrapper>
        </div>
      </Content>
    </Wrapper>
  );
}

export default Panel;