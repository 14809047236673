export const APP_URL = 'podrimskymvrchem.cz';
export const RM_URL = 'https://realitni-marketing.cz/';
export const CONTACT_PHONE = '+420 xxx xxx xxx';
export const CONTACT_MAIL = 'rs@realspektrum.cz';
export const IDENTITY = 'xxx';
export const VAT = 'xxx';
export const OFFICE = 'xxx';
export const MESSAGE_URL = '/api';
export const RECAPTCHA_SITE_KEY = '6Lc76EweAAAAAMlAXPsyajCg4UIEco7nWtgjrzEk';

export const FB_LINK = 'https://www.facebook.com/PodRimskymvrchem';
export const IG_LINK = 'https://www.instagram.com/podrimskymvrchem_prodej/';
export const YT_LINK = 'https://www.youtube.com/channel/UCTsHmsiAvzYVjlM59j6FDWA';