import React from 'react';
import { bool } from 'prop-types';
import { graphql } from 'gatsby';
import styled, {ThemeProvider} from 'styled-components';
import theme from '../../theme';

const PageWrapper = styled.div`
  max-width: 1920px;
  background-color: ${({theme}) => theme.colors.white};
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

function Layout({ showPageTitle, children }) {

  return (
    <ThemeProvider theme={theme}>
    <PageWrapper>
      <Content>
        <main>{children}</main>
      </Content>
    </PageWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  showPageTitle: bool,
}

Layout.defaultProps = {
  showPageTitle: false,
}

export default Layout;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;